<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col>
        <v-card max-width="800" class="mx-auto">
          <v-toolbar class="grey lighten-5 toolbar-custom" elevation="0"
                     style="background-color: #f7f7f7 !important;
                     border-bottom: 2px solid #e8e8e8 !important;">
            <v-toolbar-title style="font-size: 1.3rem">
              Формування файлу із тарифами та заборгованістю для Пенсійного фонду
            </v-toolbar-title>
          </v-toolbar>
          <v-navigation-drawer
              right
              app
              width="400"
              v-model="document_state.show"
              style="z-index: 9999"
              color="grey lighten-4"
          >
            <v-card tile flat class="transparent">
              <v-card-text class="pa-0">
                <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
                  <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
                  <div style="flex: 0 0 30px">
                    <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                      <v-icon color="secondary">mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-text class="px-2 pt-2 pb-2">
                <div>
                  <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                       class="elevation-3 white"
                       v-for="(item, item_idx) in document_state.errors"
                       :key="`error-${item_idx}`"
                  >
                    <div class="py-1 px-2">
                      <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                        {{ item }}
                      </div>
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-navigation-drawer>
          <v-card-text class="mt-2">
            <v-row class="dense-wrapper">
              <v-col cols="12" class="pt-6  pb-1">
                <v-alert type="success" outlined class="mb-0">
                  Для коректного заповнення файлу має бути заповнений код КАТОТТГ та к-сть поверхів у будинку для послуги з утримання будинку.
                  Якщо ви вимкнули перемикач "Використовувати коди КАТОТТГ" - назви областей, районів, територіальних громад та населених пунктів мають співвпадати із довідником КАТОТТГ"
                </v-alert>
              </v-col>

              <v-col cols="12" md="6" class="pt-0">
                <v-switch
                    class="mt-1"
                    v-model="all_flats"
                    hide-details
                    label="Усі абоненти організації"
                    color="secondary"/>
              </v-col>

              <v-col cols="12" md="6" class="pt-0">
                <v-switch
                    class="mt-1"
                    v-model="by_codes"
                    hide-details
                    label="Використовувати коди КАТОТТГ"
                    color="secondary"/>
              </v-col>

              <template v-if="!all_flats">
                <v-col cols="12">
                  <AddressElementSelect
                      label="Об’єднана громада" v-model="amalgamated_hromada_id"
                      filled select_type="amalgamated_hromada" :disabled="all_flats"
                  />
                </v-col>
                <v-col cols="12">
                  <AddressElementSelect
                      label="Населений пункт" v-model="city_id"
                      :parent_id="amalgamated_hromada_id" :use_parent_id="true"
                      filled select_type="city" :disabled="all_flats"
                  />
                </v-col>
                <v-col cols="12">
                  <AddressElementSelect
                      label="Район міста" v-model="city_area_id"
                      :parent_id="city_id" :use_parent_id="true"
                      filled select_type="city-area" :disabled="all_flats"
                  />
                </v-col>
                <v-col cols="12" v-if="city_area_id">
                  <AddressElementSelect
                      label="Вулиця" v-model="streets" multiple
                      :parent_id="city_area_id" :use_parent_id="true"
                      filled select_type="street-with-city-area" :disabled="all_flats"
                  />
                </v-col>
                <v-col cols="12" v-else>
                  <AddressElementSelect
                      label="Вулиця(-ці)" v-model="streets" multiple
                      :parent_id="city_id" :use_parent_id="true"
                      filled select_type="street" :disabled="all_flats"
                  />
                </v-col>
                <v-col cols="12">
                  <AddressElementSelect
                      label="Будинок(-ки)" v-model="buildings" multiple
                      :parent_id="streets" :use_parent_id="true"
                      filled select_type="building" :disabled="all_flats"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <Checker :value="checker_id" @autocompleteChange="onCheckerChange" :disabled="all_flats"/>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select v-model="building_type"
                            :items="buildingTypes_select"
                            label="Вид житла" :disabled="all_flats"
                            hide-details filled color="grey"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="postal_index" label="Поштовий індекс" v-integer :disabled="all_flats"
                                filled hide-details color="grey"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="building_number" label="Номер будинку" :disabled="all_flats"
                                filled hide-details color="grey"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="flat_number" label="Номер квартири" :disabled="all_flats"
                                filled hide-details color="grey"
                  />
                </v-col>
              </template>

              <v-col cols="12" sm="12" md="6">
                <month-component label="Місяць" v-model="month" :block="true" :button_style="'height: 100%'"/>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field hide-details filled
                              label="Місяців заборгованості"
                              v-model.number="debt_period"
                              v-integer color="grey"
                />
              </v-col>

              <v-col cols="12">
                <v-btn depressed text block color="secondary darken-1" class="button-accept"
                       :loading="loading"
                       @click.stop="crud_operation">
                  Виконати
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import fillTablesAPI from '@/utils/axios/fill_tables'
import MonthComponent from "@/components/MonthComponent.vue";
import {buildingTypes_select} from "@/utils/icons";

export default {
  name: "FillPensionTariffXml",
  components: {
    MonthComponent,
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect'),
    Checker: () => import("@/components/autocomplite/Checker")
  },
  computed: {
    ...mapGetters({
      current_month: 'getCurrentMonth'
    })
  },
  data() {
    return {
      loading: false,
      month: null,
      debt_period: 1,
      all_flats: false,
      by_codes: false,
      checker_id: null,
      amalgamated_hromada_id: null,
      city_id: null,
      city_area_id: null,
      streets: [],
      buildings: [],
      postal_index: null,
      building_type: null,
      building_number: null,
      flat_number: null,
      buildingTypes_select,
      document_state: {
        ready: false,
        show: false,
        errors: []
      },
    }
  },
  methods: {
    onCheckerChange(payload) {
      this.checker_id = (payload || {}).value || null
    },
    documentReady() {
      this.document_state.errors = []
      this.document_state.ready = false

      if (!this.all_flats) {
        if (!this.streets.length && !this.city_id && !this.buildings.length) {
          this.document_state.ready = false
        }
      }
      this.document_state.ready = !(!this.month || !this.debt_period);

      if (!this.document_state.ready) this.document_state.show = true
    },
    crud_operation() {
      this.documentReady()
      if (this.document_state.ready) {
        this.loading = true

        const payload = {
          all_flats: this.all_flats,
          checker_id: this.checker_id,
          amalgamated_hromada_id: this.amalgamated_hromada_id,
          city_id: this.city_id,
          city_area_id: this.city_area_id,
          streets: this.streets,
          buildings: this.buildings,
          building_type: this.building_type,
          postal_index: this.postal_index,
          building_number: this.building_number,
          flat_number: this.flat_number,
          month: this.month,
          debt_period: this.debt_period,
          by_codes: this.by_codes
        }
        fillTablesAPI.pension_fund(payload)
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a');
              const file_name = response.headers['x-blob-file-name'] || 'tax_bills_outcome.zip'
              link.href = url;
              link.setAttribute('download', file_name); //or any other extension
              document.body.appendChild(link);
              link.click();
            })
            .catch(err => {
              err.response.data.text()
                  .then(data => {
                    const json = JSON.parse(data)
                    this.document_state.errors = (json.detail || '').split('; ')
                    this.document_state.show = true
                    // this.$store.dispatch(ALERT_SHOW, {text: json.detail, color: 'error lighten-1'})
                  })

            })
            .finally(() => {
              this.loading = false
            })
      }
    },
  },
  created() {
    this.month = this.current_month
    this.debt_period = 3
    this.all_flats = true
    this.by_codes = true
  },
  watch: {
    current_month(value) {
      this.month = value
    }
  }
}
</script>

<style scoped>

</style>